<template>
  <div>
    <el-form
      v-loading="loading"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="队伍名称"
        prop="sect_name"
        v-if="type == 'wdl' && is_sect == 0"
      >
        <el-col :span="6">
          <el-input
            v-model="dataForm.sect_name"
            placeholder="输入队伍名称"
            maxlength="64"
            show-word-limit
            :disabled="view_type == 'edit'"
            :display="view_type == 'edit'"
          ></el-input>
        </el-col>
      </el-form-item>
      <!-- 门派 -->
      <el-form-item
        v-else
        id="sect_id"
        label="门派"
        prop="sect_id"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="sect_id"
            v-model="dataForm.sect_id"
            readonly
          ></el-input>
        </el-col>
        <el-col :span="6" v-if="view_type != 'edit'">
          <sectSelect @onSelect="onSectSelect">
            <el-button type="default" size="mini" style="margin-left: 5px">{{
              dataForm.sect_id && dataForm.sect_id.length
                ? "点击修改门派"
                : "点击选择门派"
            }}</el-button>
          </sectSelect>
        </el-col>
      </el-form-item>
      <!-- 门派名称 -->
      <el-form-item
        v-if="is_sect != 0"
        id="sect_name"
        label="门派名称"
        prop="sect_name"
        style="margin-bottom: 35px"
      >
        <el-col :span="12">
          {{ dataForm.sect_name }}
        </el-col>
      </el-form-item>
      <!-- 门派logo -->
      <el-form-item label="门派logo" prop="sect_icon">
        <el-upload
          class="avatar-uploader"
          :show-file-list="false"
          :before-upload="beforeLogoImageUpload"
          action="#"
          :http-request="httpLogoUploadRequest"
        >
          <img
            v-if="dataForm.sect_icon"
            :src="dataForm.sect_icon"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <!-- 领队 -->
      <el-form-item label="领队" prop="leader.uid">
        <memberSelectItem
          :blackList="black_list"
          @getMembers="getMembers"
          :member.sync="dataForm.leader"
          :type="'leader'"
          title="领队"
          :members="members"
          :team.sync="dataForm"
          :index="-1"
          @onSelect="onLeaderSelect"
          @onRemove="onLeaderRemove"
        >
          <div>
            <i class="el-icon-plus avatar-uploader-icon" style=""></i>
            <div>点击选取领队</div>
          </div>
        </memberSelectItem>
      </el-form-item>
      <!-- 领队微信 -->
      <el-form-item label="领队微信" prop="leader_wechat">
        <el-col :span="6">
          <el-input
            v-model="dataForm.leader_wechat"
            placeholder="输入领队微信"
            maxlength="64"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <!-- 选手 -->
      <el-form-item label="选手">
        <div
          style="display: grid; grid-template-columns: repeat(5, 1fr);grid-column-gap: 5px; grid-row-gap: 5px;"
        >
          <memberSelectItem
            v-for="(i, item) in numberNum"
            :key="`member_${i}`"
            :type="'member'"
            :need_wechat="dataForm.members"
            :blackList="black_list"
            :member="dataForm.members.find((r) => r.index == item) || false"
            :title="
              matchType != 'league' ? `第${item + 1}场` : `选手${item + 1}`
            "
            :members.sync="members"
            :team.sync="dataForm"
            :match_type = "match_type"
            :index="i"
            @onSelect="onMemberSelect"
            @onRemove="onMemberRemove"
            @getMembers="getMembers"
          >
            <div>
              <i class="el-icon-plus avatar-uploader-icon" style=""></i>
              <div>{{ `点击选取第${item + 1}场选手` }}</div>
            </div>
          </memberSelectItem>
        </div>
      </el-form-item>

      <el-form-item label="替补" v-if="false">
        <memberSelectItem
          :blackList="black_list"
          :member="dataForm.alternate[0] || false"
          :type="'alternate'"
          title="替补"
          :members="members"
          :team.sync="dataForm"
          :index="0"
          @onSelect="onAlternateSelect"
          @onRemove="onAlternateRemove"
        >
          <div>
            <i class="el-icon-plus avatar-uploader-icon" style=""></i>
            <div>点击选取替补</div>
          </div>
        </memberSelectItem>
      </el-form-item>

      <el-form-item label="小程序跳转链接" prop="link" v-if="type == 'wdl'">
        <el-col :span="6">
          <el-input
            v-model="dataForm.link"
            placeholder="输入小程序跳转链接"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="H5跳转链接" prop="link_h5" v-if="type == 'wdl'">
        <el-col :span="6">
          <el-input
            v-model="dataForm.link_h5"
            placeholder="输入H5跳转链接"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item style="margin-top: 50px; text-align: center">
        <perButton
          type="primary"
          perm="activity/save"
          @click="submitForm()"
          size="large"
          >提交</perButton
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import perButton from "@/components/perm/perButton";
import sectSelect from "../../components/sectSelect";
import memberSelectItem from "../../components/memberSelectItem";
import { randomStr } from "@/utils/randomStr";
import { uploadOssUrl } from "@/utils/uploadUrl";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  name: "team_manager",
  components: {
    perButton,
    sectSelect,
    memberSelectItem,
  },
  props: {
    team_data: {
      type: Object,
    },
    match_id: {
      type: String,
    },
    view_type: {
      type: String,
    },
    match_type :{
      type: String,
    },
    season_id :{
       type: String,
     }
  },
  // computed: {
  //   typePage() {
  //     return this.view_type;
  //   },
  // },
  data() {
    let self = this;
    return {
      is_sect: null,
      numberNum: [],
      type: "s1",
      members: [],
      black_list: [],
      dataForm: {
        sect_id: "",
        sect_name: "",
        sect_icon: "",
        leader_wechat: "",
        leader: {
          uid: "",
          name: "",
          avatar: "",
          job: "",
        },
        members: [],
        alternate: [],
        link: "",
        link_h5: "",
      },
      rules: {
        sect_name: [
          {
            required: true,
            message: "请输入门派名称",
            trigger: ["blur"],
          },
        ],
        sect_id: [
          {
            required: true,
            message: "请选择门派",
            trigger: ["blur", "change"],
          },
        ],
        "leader.uid": [
          {
            required: true,
            message: "请添加领队",
            trigger: ["blur", "change"],
          },
        ],
        sect_icon: [
          {
            required: true,
            message: "请上传门派logo",
            trigger: ["blur", "change"],
          },
        ],
        "board_game_bar.desc": [
          {
            required: true,
            message: "请输入桌游吧介绍",
            trigger: ["blur", "change"],
          },
        ],
        leader_wechat: [
          {
            required: true,
            message: "请输入领队微信",
            trigger: ["blur", "change"],
          },
        ],
      },
      loading: false,
    };
  },
  //保或时使用activated
  activated() {
    this.setFormData();
  },
  watch: {
    team_data: {
      handler: function(val) {
        this.setFormData();
      },
      immediate: true,
    },
    $route: {
      handler: function(val) {
        this.matchType = localStorage.getItem("matchType");
        this.type = val.query.type_id;
        this.is_sect = val.query.is_sect;
        if (this.type == "s1_pro" || this.type == "s2_pro") {
          this.numberNum = [0, 1, 2, 3];
        } else if (this.type == "wdl") {
          this.numberNum = [0, 1, 2];
        } else {
          this.numberNum = [0, 1, 2, 3, 4, 5, 6, 7, 8];
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    // this.type = this.$route.query.type_id;
    this.loading = true;
    // this.setFormData();
    // let data = await axios.get(
    //   "https://mini-community-cdn.langrensha01.cn/" +
    //     process.env.VUE_APP_MATCH_ASSETS_PATCH +
    //     "/user_black_list.json?r=" +
    //     Math.random()
    // );

    // if (data) {
    //   this.black_list = data.data.map((r) => r.UID);
    // }
    // console.log("dddddd", this.black_list);

    let res = await this.$http.match.member_black_uid();
    this.black_list = res.data;
    this.loading = false;
  },
  methods: {
    beforeLogoImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      return isJPG && isLt2M;
    },
    getMembers(params) {
      this.members = params;
    },
    async httpLogoUploadRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().teams + fileName;

      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.dataForm.sect_icon = result;
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },
    onSectSelect(data) {
      this.dataForm.sect_id = data.sect_id;
      this.dataForm.sect_name = data.sect_name;
      this.members = data.members;
    },
    async setFormData() {
      let data = this.team_data;
      if (data && data._id) {
        this.dataForm.sect_id = data.sect_id;
        this.dataForm.sect_name = data.sect_name;
        this.dataForm.sect_icon = data.sect_icon;
        this.dataForm.leader_wechat = data.leader_wechat;
        this.dataForm.link = data.link;
        this.dataForm.link_h5 = data.link_h5;
        this.dataForm.leader = {
          uid: data.leader_uid,
          name: data.leader_name,
          avatar: data.leader_avatar,
          job: data.leader_job,
        };
        this.dataForm.members = data.members;
        this.dataForm.alternate = data.alternate;
        let matchType = localStorage.getItem("matchType");
        //只有预选赛s1的时候为false
        if (this.type != "s1") {
          this.dataForm.is_bind = false;
        } else {
          this.dataForm.is_bind = true;
        }
        if (this.view_type == "edit" && this.dataForm.sect_id) {
          this.loading = true;
          let { data, errorCode } = await this.$http.match.getSectMembers(
            this.dataForm.sect_id,
            this.dataForm.is_bind
          );
          this.loading = false;
          if (errorCode != "0000") {
            this.removeTabByPath(`/match/info/info_s1/add_team`);
            this.removeTabByPath(`/match/info/info_s1/edit_team`);
            return;
          }
          if (data) {
            this.dataForm.sect_id = data.sect_id;
            this.dataForm.sect_name = data.sect_name;
            this.members = data.members;
          }
        }
      } else {
        this.dataForm = {
          sect_id: "",
          sect_name: "",
          sect_icon: "",
          leader_wechat: "",
          leader: {
            uid: "",
            name: "",
            avatar: "",
            job: "",
          },
          link: "",
          link_h5: "",
          members: [],
          alternate: [],
        };
        this.$nextTick(() => {
          this.$refs["dataForm"].clearValidate();
        });
      }
    },
    // 提交
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
       
        if (valid) {
          let d = this.getSaveData();
           let endpoint= ''
          if (this.type == "wdl") {
            d.is_sect = this.is_sect;
            endpoint = await this.$http.match.save_team_wdl(d);
          } else if(this.match_type === 'match_s4'){
            delete d.sect_name;
            d.season_id = this.season_id,
            console.log('d',d);
           endpoint = await this.$http.match_subject.new_save_team(d);
          } else {
            delete d.sect_name;
            endpoint = await this.$http.match.save_team(d);
          }
          if (endpoint.code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.$bus.$emit("onTeamChanged", endpoint.data);
            this.removeTabByPath(`/match/info/info_s1/add_team`);
            this.removeTabByPath(`/match/info/info_s1/edit_team`);
            if (this.match_type === 'match_s4') {
               this.$router.push({
              path: `/match/subject/team`,
              query: { match_id: this.match_id, type_id: this.type ,season_id:this.season_id,match_type:this.match_type},
            });
            }else {
                this.$router.push({
              path: `/match/info/info_s1/team`,
              query: { _id: this.match_id, type_id: this.type },
            });
            }
          
          }
        } else {
          console.log("error submit!!");
          console.log(invalidFields);
          let fileds = Object.keys(invalidFields);
          if (fileds.length) {
            let id1 = fileds[0];
            if (document.getElementById(id1)) {
              document
                .getElementById(id1)
                .scrollIntoView({ behavior: "smooth" });
            }

            let refKey = id1.replace(/\./g, "_");
            if (this.$refs[refKey]) {
              this.$refs[refKey].focus();
            }
          }
          return false;
        }
      });
    },
    getSaveData() {
      let data = {
        match_id: this.match_id,
        link: this.dataForm.link,
        link_h5: this.dataForm.link_h5,
        sect_id: this.dataForm.sect_id,
        sect_icon: this.dataForm.sect_icon,
        leader_wechat: this.dataForm.leader_wechat,
        sect_name: this.dataForm.sect_name,
        leader: { uid: this.dataForm.leader.uid },
        members: this.dataForm.members.map((r) => {
          return {
            uid: r.uid,
            index: r.index,
            wechat: r.wechat,
          };
        }),
        alternate: this.dataForm.alternate.map((r) => {
          return {
            uid: r.uid,
          };
        }),
        _id: "",
      };

      if (this.view_type == "edit") {
        data._id = this.team_data._id;
      }

      return data;
    },
    resetForm() {
      this.$refs.dataForm.resetFields();
    },
    onLeaderSelect(data) {
      console.log("ceshi", data.data);
      this.dataForm.leader = {
        uid: data.data.uid,
        avatar: data.data.avatar,
        name: data.data.name,
        job: data.data.job,
      };
      this.dataForm.leader_wechat  = data.data.wechat

    },
    onLeaderRemove(data) {
      if (data.data.uid == this.dataForm.leader.uid) {
        this.dataForm.leader = {
          uid: "",
          name: "",
          avatar: "",
          job: "",
        };
      }
    },
    onMemberSelect(data) {
      let index = this.dataForm.members.findIndex((r) => r.index == data.index);
      if (index >= 0) {
        this.dataForm.members.splice(index, 1);
      }

      this.dataForm.members.push({
        ...data.data,
        index: data.index,
      });
    },
    onMemberRemove(data) {
      let index = this.dataForm.members.findIndex((r) => r.index == data.index);
      if (index >= 0) {
        this.dataForm.members.splice(index, 1);
      }
    },
    onAlternateSelect(data) {
      this.dataForm.alternate = [
        {
          ...data.data,
          index: data.index,
        },
      ];
    },
    onAlternateRemove(data) {
      this.dataForm.alternate = [];
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
};
</script>

<style lang="less" scoped>
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 38px;
  color: rgb(0, 153, 255);
  text-align: center;
}

.avatar {
  width: 200px;
  height: 200px;
}
</style>
